<template>
  <Page>
    <template #title>
      Billing
    </template>

    <TbLoading
      v-if="state === PageState.loading"
    />

    <div v-else>
      <h2 class="my-6 text-xl font-regular">
        Metrics associated with the in-house payment plans you offer via Elective.
      </h2>
      <div v-if="availableCurrencies && availableCurrencies.length > 1">
        <TbSelect
          v-model="selectedCurrency"
          :options="selectOptionsCurrency"
          :default-options="{ name: 'USD', value: CurrencyCode.USD }"
          label="Currency"
          class="w-full sm:w-48 mb-2"
        />
      </div>
      <div class="p-4 mb-4 bg-white card">
        <h2 class="mb-2 text-lg font-bold">
          Payments by Month
        </h2>

        <div class="flex flex-wrap gap-8 analytics">
          <div class="flex flex-col">
            <h3 class="text-base text-gray-500 font-regular">
              Last Month (Paid)
            </h3>
            <h4 class="text-lg currency">
              <span class="currency">{{ selectedCurrency + currencyInCents(analytics.previousMonth) }}</span>
            </h4>
          </div>

          <div class="flex flex-col">
            <h3 class="text-base text-gray-500 font-regular">
              This Month (Expected)
            </h3>
            <h4 class="text-lg currency">
              <span class="currency">{{ selectedCurrency + currencyInCents(analytics.currentMonth) }}</span>
            </h4>
          </div>

          <div class="flex flex-col">
            <h3 class="text-base text-gray-500 font-regular">
              Next 3 Months (Expected)
            </h3>
            <h4 class="text-lg currency">
              <span class="currency">{{ selectedCurrency + currencyInCents(analytics.next3Months) }}</span>
            </h4>
          </div>

          <div class="flex flex-col">
            <h3 class="text-base text-gray-500 font-regular">
              Next 12 Months (Expected)
            </h3>
            <h4 class="text-lg currency">
              <span class="currency">{{ selectedCurrency + currencyInCents(analytics.next12Months) }}</span>
            </h4>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <div class="grid grid-cols-1 gap-4 mb-4 lg:grid-cols-3 lg:grid-rows-1">
          <div class="p-4 bg-white card">
            <div class="flex flex-col">
              <h3 class="mb-2 text-lg font-bold">
                Lifetime Failed Charges
              </h3>
              <h4 class="text-lg currency">
                <span class="currency">{{ selectedCurrency + currencyInCents(analytics.lifetimeFailed) }}</span>
              </h4>
            </div>
          </div>
          <div class="p-4 bg-white card">
            <div class="flex flex-col">
              <h3 class="mb-2 text-lg font-bold">
                Lifetime Recovered Revenue
              </h3>
              <h4 class="text-lg currency">
                <span class="currency">{{ selectedCurrency + currencyInCents(analytics.lifetimeRecovered) }}</span>
              </h4>
            </div>
          </div>
          <div class="p-4 bg-white card">
            <div class="flex flex-col">
              <h3 class="mb-2 text-lg font-bold">
                Collections Rate
              </h3>
              <h4 class="text-lg currency">
                <span>{{ analytics.collectionsRate ? percentage(analytics.collectionsRate) : '-%' }}</span>
              </h4>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap justify-between gap-4 p-4 bg-white card">
          <div class="flex-grow w-[60%] flex justify-center flex-col">
            <p>
              Currently recovering <span class="currency">
                {{ selectedCurrency + currencyInCents(analytics.inRecovery) }}
              </span> failed charges.
            </p>

            <p>
              Elective has recovered <span class="currency">
                {{ selectedCurrency + currencyInCents(analytics.lifetimeRecovered) }}
              </span> for you.
            </p>
          </div>

          <div class="flex justify-between flex-grow">
            <div>
              <h3 class="mb-2 text-lg font-bold">
                In-Recovery Balance
              </h3>
              <p class="currency">
                {{ selectedCurrency + currencyInCents(analytics.inRecovery) }}
              </p>

              <p class="text-gray-500">
                1-30 days past due
              </p>
            </div>

            <div class="h-full w-[2px] rounded-lg bg-gray-300 mr-2" />

            <div>
              <h3 class="mb-2 text-lg font-bold">
                Unrecoverable Balance
              </h3>
              <p class="currency">
                {{ selectedCurrency + currencyInCents(analytics.unRecoverable) }}
              </p>

              <p class="text-gray-500">
                30+ days past due
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { ref, onActivated, watch } from 'vue';
import { useRoute } from 'vue-router';
import { TbSelect, TbLoading } from '@/components/tasty_bistro';
import { PageState, CurrencyCode } from '@/types';
import { currencyInCents } from '@/filters/currency';
import { percentage } from '@/filters/percentage';
import { getAnalytics, Analytics } from '../api/get_analytics';
import Page from '../../components/page.vue';

const route = useRoute();
const projectId = route.params.projectId as string;

const analytics = ref<Analytics>({
  previousMonth: 0,
  currentMonth: 0,
  next3Months: 0,
  next12Months: 0,
  lifetimeFailed: 0,
  lifetimeRecovered: 0,
  inRecovery: 0,
  unRecoverable: 0,
  collectionsRate: 0,
});

const state = ref<PageState>(PageState.loaded);
const availableCurrencies = ref<CurrencyCode[]>();
const currency = ref(CurrencyCode.USD);
const selectedCurrency = ref();

const selectOptionsCurrency = [
  { name: 'CAD', value: CurrencyCode.CAD },
];

const loadAnalytics = async () => {
  state.value = PageState.loading;
  try {
    const data = await getAnalytics({
      projectId,
      currency: (availableCurrencies.value && availableCurrencies.value.length > 1)
        ? currency.value
        : selectedCurrency.value,
    });
    availableCurrencies.value = data.currencies;
    selectedCurrency.value = data.selectedCurrency;
    analytics.value = data.analyticsResponse;
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

onActivated(async () => {
  loadAnalytics();
});

watch(currency, async () => {
  await loadAnalytics();
});
</script>
