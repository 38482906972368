import http, { Http } from '@/lib/http';
import { CurrencyCode } from '@/types';

export interface Analytics {
  previousMonth: number;
  currentMonth: number;
  next3Months: number;
  next12Months: number;
  lifetimeFailed: number;
  lifetimeRecovered: number;
  inRecovery: number;
  unRecoverable: number;
  collectionsRate: number;
}

interface Data {
  currency: CurrencyCode | null;
  projectId: string;
}

function getAnalyticsCtor(http: Http) {
  return async ({ currency, projectId }: Data): Promise<{
    analyticsResponse: Analytics,
    currencies: CurrencyCode[],
    selectedCurrency: CurrencyCode
  }> => {
    const { data } = await http.get(`/projects/${projectId}/ppm/payments/analytics`, { params: { currency } });
    return data;
  };
}

export const getAnalytics = getAnalyticsCtor(http);
