<template>
  <div class="h-full w-full">
    <div
      class="h-full flex flex-col gap-2"
    >
      <!-- heading -->
      <div class="flex flex-wrap justify-between gap-4">
        <h2 class="text-lg font-bold">
          {{ action === 'create' ? 'Create' : 'Update' }} Checkout
        </h2>

        <StatusSelect
          v-model="status"
          :disabled="formState === FormState.submitting"
          class="w-[220px]"
          :options="statusOptions"
          :errors="statusError && [statusError]"
        />
      </div>
      <!-- content -->
      <div
        class="flex-grow w-full overflow-hidden"
      >
        <div class="h-full overflow-auto grow-1 flex flex-col">
          <Note
            v-if="warnings.length"
            variant="warning"
            class="my-3"
          >
            <ol class="list-decimal ml-4">
              <li
                v-for="warning in warnings"
                :key="warning"
              >
                {{ warning }}
              </li>
            </ol>
          </Note>

          <section>
            <TbInput
              v-model="name"
              placeholder="Checkout name"
              class="mb-2"
              :disabled="formState === FormState.submitting"
              label="Name"
              :errors="nameError && [nameError]"
            />

            <!-- Terms -->
            <div class="flex flex-col">
              <div class="flex flex-row gap-2 items-center mr-1 label">
                <label class="flex items-center text-primary font-medium text-sm ml-2 mt-1 mb-1">Terms of Service Link (optional)</label>
                <Tooltip>
                  <template #trigger="{ show, assignReference, hide }">
                    <svg
                      :ref="(el: any) => assignReference(el)"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-5 h-5 text-gray-300"
                      @mouseenter="show"
                      @mouseleave="hide"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75
                      4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385
                      2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126
                      1.706l-.709 2.836.042-.02a.75.75 0 01.67
                      1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75
                      0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </template>

                  <template #tooltip>
                    This is the URL where your customers can find your terms of
                    service. If you don't have one, you can leave this blank.
                  </template>
                </Tooltip>
              </div>

              <TbInput
                v-model="termsUrl"
                placeholder="https://example.com/terms-of-service"
                class="mb-4"
                :disabled="formState === FormState.submitting"
                :errors="termsUrlError && [termsUrlError]"
              />
            </div>

            <TbInput
              v-model="redirectUrl"
              :disabled="formState === FormState.submitting"
              label="Thank You Page Link (optional)"
              placeholder="https://example.com/thank-you"
              :errors="redirectUrlError && [redirectUrlError]"
            />
          </section>

          <section class="py-3">
            <div class="flex flex-col mb-3">
              <h3 class="text-[16px] font-bold">
                Setup
              </h3>
              <p>
                Assign products to your checkout, and select payment settings for your
                customers to check out with.
              </p>
            </div>

            <!-- Product selection -->
            <div class="flex flex-row gap-2 pb-3">
              <Combobox
                label="Products"
                :searchBy="'name'"
                :options="filteredProducts"
              >
                <template #placeholder>
                  {{ selectedProductsCount === 0
                    ? 'Assign one or multiple products'
                    : `${selectedProductsCount} products selected.` }}
                </template>
                <template #default="{ item }">
                  <div
                    :class="[
                      'px-2 py-3 flex flex-row items-center gap-2 cursor-pointer',
                      item.disabled ? 'opacity-50' : '',
                    ]"
                    @click="selectProduct(item.id)"
                  >
                    <input
                      :checked="
                        selectedProducts.find((p) => p.id === item.id) ? true : false
                      "
                      type="checkbox"
                      class="checked:bg-primary-800 pointer-events-none"
                      @click="selectProduct(item.id)"
                    >
                    <div class="truncate w-full text-sm">
                      {{ item.name }}
                    </div>
                    <p class="text-sm shrink-0">
                      {{ item.currencyCode }} {{ currencyInCents(item.priceInCents) }}
                    </p>
                  </div>
                </template>
              </Combobox>
              <button
                style="box-shadow: none"
                class="self-end button button--sm bg-gray-100"
                @click="emit('new-product')"
              >
                <span class="flex items-center gap-2">
                  <TbPlusIcon class="w-5 h-5 text-primary" />
                  Create
                </span>
              </button>
            </div>

            <!-- selected products preview -->
            <div
              v-if="selectedProducts.length"
              class="flex flex-row flex-wrap gap-1 pb-3"
            >
              <div
                v-for="product of selectedProducts"
                :key="product.id"
                class="flex flex-row items-center gap-3 max-w-[200px] py-1 text-sm px-4 rounded-md bg-primary-900 text-white cursor-pointer"
              >
                <div class="truncate">
                  {{ product.name }}
                </div>
                <TbXMarkIcon
                  class="h-4 w-4 flex-shrink-0"
                  @click="selectProduct(product.id)"
                />
              </div>
            </div>

            <div
              v-if="selectedCurrency"
              class="py-3"
            >
              Products total: {{ selectedCurrency }} {{ currencyInCents(selectedTotalInCents) }}
            </div>

            <!-- Payment settings -->
            <div class="flex flex-row gap-2">
              <Combobox
                label="Payments"
                :searchBy="'name'"
                :options="formattedPaymentSettings"
              >
                <template #placeholder>
                  {{ selectedPaymentSettings
                    ? `${selectedPaymentSettings.name}`
                    : 'Select payment settings' }}
                </template>
                <template #default="{ item, close }">
                  <div
                    :class="[
                      'px-2 py-3 flex flex-row items-center gap-2 cursor-pointer hover:bg-gray-100 rounded-md transition',
                      item.disabled ? 'opacity-50' : '',
                    ]"
                    @click="selectPaymentSettings(item.id); close()"
                  >
                    <div class="w-full flex flex-col gap-2 font-bold text-brand">
                      <div class="truncate">
                        {{ item.name }}
                      </div>

                      <div class="flex flex-col gap-1 text-sm">
                        <div
                          v-for="(feature, k) in [
                            {
                              label: 'Elective Billing',
                              enabled: item.paymentPlans.filter((pp: any) => pp.installments !== 1).length > 0
                            },
                            { label: 'Pay in Full', enabled: item.paymentPlans.find((pp: any) => pp.installments === 1) },
                            { label: 'Learn now, Pay later', enabled: item.lnplEnabled },
                          ]"
                          :key="
                            k"
                          class="flex flex-row items-center gap-1"
                        >
                          <div class="truncate flex-grow text-gray-500 font-regular font-normal">
                            {{ feature.label }}
                          </div>
                          <div
                            :class="[
                              'w-5 h-5 flex items-center justify-center flex-shrink-0',
                              feature.enabled ? 'text-success-900' : 'text-gray-400',
                            ]"
                          >
                            <svg
                              v-if="feature.enabled"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              class="h-5/6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                            <TbXMarkIcon
                              v-else
                              class="h-5/6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Combobox>
              <button
                style="box-shadow: none"
                class="self-end button button--sm bg-gray-100"
                @click="emit('new-stack')"
              >
                <span class="flex items-center gap-2">
                  <TbPlusIcon class="w-5 h-5 text-primary" />
                  Create
                </span>
              </button>
            </div>
            <div
              v-if="selectedPaymentSettings?.ppmEnabled"
              class="flex flex-col gap-2 pt-4"
            >
              <Checkbox
                v-model="downPaymentEnabled"
                alignment="right"
              >
                <template #label>
                  <div>Down Payment</div>
                </template>
              </Checkbox>

              <!-- Selection -->
              <div
                v-if="downPaymentEnabled && downPaymentType"
                class="w-1/3"
              >
                <CurrencyPercentage
                  :type="downPaymentType"
                  :value="downPaymentValue"
                  :totalAmount="selectedTotalInCents"
                  :maxInCents="selectedTotalInCents"
                  placeholder="Not Set"
                  @change-type="downPaymentType = $event"
                  @change-value="downPaymentValue = $event"
                />
              </div>
            </div>

            <!-- Custom first payment -->
            <Collapse
              v-if="paymentSettingsId"
              :disabled="!paymentSettingsId"
            >
              <template #label="{ open, disabled }">
                <Tooltip
                  :disabled="!disabled"
                >
                  <template #trigger="{ assignReference, show, hide }">
                    <div
                      :ref="(r: any) => assignReference(r)"
                      class="flex flex-row text-gray-400 items-center gap-3 pt-4"
                      @mouseenter="show"
                      @mouseleave="hide"
                    >
                      Payment options breakdown
                      <TbChevronDownIcon
                        :class="[
                          'h-5 w-5 transition duration-150 ease-in-out',
                          open && 'transform rotate-180',
                        ]"
                      />
                    </div>
                  </template>

                  <template #tooltip>
                    Please select a payment setting to view the breakdown.
                  </template>
                </Tooltip>
              </template>

              <template #content>
                <div class="flex flex-col gap-3 pt-2">
                  <div
                    v-if="selectedPaymentSettings?.lnplEnabled"
                    class="flex flex-row justify-between"
                  >
                    <div class="flex flex-row gap-3 items-center font-regular">
                      <TbChartPieIcon class="w-5 h-5" />
                      Learn now, Pay later
                    </div>
                    <TbChip
                      :color="lnplDisabled ? 'error' : 'primary'"
                      small
                      :text="lnplDisabled ? 'Disabled' : 'Enabled'"
                    />
                  </div>

                  <div
                    v-if="selectedPaymentSettings?.ppmEnabled"
                    class="flex flex-row justify-between"
                  >
                    <div class="flex flex-row gap-3 items-center font-regular">
                      <TbCreditCardIcon class="w-5 h-5" />
                      Elective Billing
                    </div>
                    <TbChip
                      small
                      text="Enabled"
                    />
                  </div>

                  <table
                    v-if="paymentSettingsData.length"
                    class="table-fixed border-collapse w-full"
                  >
                    <thead>
                      <tr
                        class="border-b border-solid border-gray-300"
                      >
                        <th
                          v-for="header in stackHeaders"
                          :key="header.key"
                          :class="[
                            header.key === 'numberOfPayments' ? 'w-1/4' : ''
                          ]"
                        >
                          <div
                            class="pl-4 py-2 text-left text-sm font-semibold text-brand whitespace-nowrap"
                          >
                            {{ header.title }}
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="row in paymentSettingsData"
                        :key="row.numberOfPayments"
                      >
                        <td>
                          <div
                            class="pl-4 py-2 text-left-50"
                          >
                            {{ row.numberOfPayments }}
                          </div>
                        </td>
                        <td>
                          <div
                            class="pl-4 py-2 text-left-50"
                          >
                            {{ selectedCurrency }} {{ row.firstPayment }}
                            <div class="text-sm text-gray-500">
                              ({{ row.firstPaymentPercentage }}%)
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            class="pl-4 py-2 text-left-50"
                          >
                            {{ selectedCurrency }} {{ row.remainingPayments }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </Collapse>
          </section>
        </div>
        <!-- overflow section -->
      </div>

      <Note
        v-if="errors.length"
        variant="error"
        class="my-3"
      >
        <ol class="list-decimal ml-4">
          <li
            v-for="errorItem in errors"
            :key="errorItem"
          >
            {{ errorItem }}
          </li>
        </ol>
      </Note>

      <!-- actions -->
      <div class="mt-4 action flex flex-col">
        <div
          v-if="error"
          class="text-error-800 text-sm py-3"
        >
          {{ error }}
        </div>
        <button
          class="button button--primary button--block"
          :disabled="formState === FormState.submitting"
          @click="emit('submit')"
        >
          {{ action === 'create' ? 'Create' : 'Update' }}
        </button>

        <button
          class="button mt-2 button--text button--block"
          :disabled="formState === FormState.submitting"
          @click="emit('cancel')"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref, onMounted, computed, inject, Ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { FormState } from '@/types';
import { AppState } from '@/pages/app/api/get_app_state';
import Dinero from 'dinero.js';
import { currencyInCents } from '@/filters/currency';
import {
  TbInput, TbPlusIcon, TbXMarkIcon, TbChevronDownIcon, TbCreditCardIcon, TbChip, TbChartPieIcon,
} from '@/components/tasty_bistro';
import Combobox from '@/components/combobox.vue';
import Tooltip from '@/components/tooltip.vue';
import Checkbox from '@/components/checkbox.vue';
import Note from '@/components/note.vue';
import StatusSelect from '@/components/status_select.vue';
import CurrencyPercentage from '../down_payment_input/index.vue';
import Collapse from '../collapse/index.vue';
import { StackPreview, getStackPreviews } from './api/get_payment_previews';
import { ProductPreview, getProductPreviews } from './api/get_product_previews';

const statusOptions = [
  { name: 'Inactive', value: 'inactive', color: 'bg-warning-900' },
  { name: 'Active', value: 'active', color: 'bg-success-900' },
];

type ExtendedProductPreview = ProductPreview & { disabled: boolean }

interface Props {
    formState: FormState;
    error: string;
    action: 'create' | 'update'
}

defineProps<Props>();

const emit = defineEmits(['cancel', 'submit', 'new-product', 'new-stack']);

const name = inject('name', ref(''));
const nameError = inject('nameError', ref(''));

const status = inject('status', ref(''));
const statusError = inject('statusError', ref(''));

const redirectUrl = inject('redirectUrl', ref(''));
const redirectUrlError = inject('redirectUrlError', ref(''));

const termsUrl = inject('termsUrl', ref(''));
const termsUrlError = inject('termsUrlError', ref(''));

const downPaymentType = inject('downPaymentType', ref<string | null>(''));
const downPaymentTypeError = inject('downPaymentTypeError', ref(''));

const downPaymentValue = inject('downPaymentValue', ref<number | null>(null));
const downPaymentValueError = inject('downPaymentValueError', ref(''));

const paymentSettingsId = inject('paymentSettingsId', ref(''));
const paymentSettingsIdError = inject('paymentSettingsIdError', ref(''));

const products = inject('products', ref<string[]>([]));
const productsError = inject('productsError', ref(''));

const downPaymentEnabled = ref(false);

watch(downPaymentEnabled, (newValue) => {
  if (!newValue) {
    downPaymentType.value = null;
    downPaymentValue.value = null;
  } else if (!downPaymentType.value) {
    downPaymentType.value = 'percentage';
    downPaymentValue.value = 0;
  }
});

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;

const route = useRoute();
const projectId = route.params.projectId as string;

const projectConfigs = appState.value.projects[projectId].configs;

const productPreviews = ref<ExtendedProductPreview[]>([]);
const paymentSettingsPreviews = ref<StackPreview[]>([]);

const selectedCurrency = inject('selectedCurrencyCode', ref<string | null>(null));

const loadPreviews = async () => {
  const list = await getProductPreviews(projectId);
  const paymentSettings = await getStackPreviews(projectId);
  paymentSettingsPreviews.value = paymentSettings.filter((s) => s.lnplEnabled || s.paymentPlans.length);
  productPreviews.value = list.map((l) => ({
    ...l,
    disabled: false,
  }));

  if (!selectedCurrency.value && products.value.length) {
    const currentProduct = productPreviews.value.find((p) => p.id === products.value[0]);
    selectedCurrency.value = currentProduct?.currencyCode || null;
  }
};

const formattedPaymentSettings = computed(() => paymentSettingsPreviews.value.map((s) => ({
  ...s,
  name: s.name,
  disabled: selectedCurrency.value === 'CAD' && s.ppmEnabled,
})));

onMounted(async () => {
  await loadPreviews();

  if (downPaymentType.value !== null && downPaymentValue.value !== null) {
    downPaymentEnabled.value = true;
  }
});

const selectedPaymentSettings = computed(() => paymentSettingsPreviews.value.find((s) => s.id === paymentSettingsId.value));
const selectedProducts = computed(() => productPreviews.value.filter((p) => products.value.includes(p.id)));

// disables divergent currencies from product list
const filteredProducts = computed(() => productPreviews.value.map((product) => {
  const disabled = !!(selectedCurrency?.value && product.currencyCode !== selectedCurrency.value);

  return {
    ...product,
    disabled,
  };
}));

const getProduct = (productId: string) => productPreviews.value.find((p) => p.id === productId);

const selectProduct = (productId: string) => {
  const productInfo = getProduct(productId);

  if (!productInfo) return;

  if (selectedCurrency.value && productInfo.currencyCode !== selectedCurrency.value) {
    return;
  }

  if (products.value.find((pid) => pid === productId)) {
    products.value = products.value.filter((p) => p !== productId);
  } else {
    products.value = [...products.value, productId];
  }
};

const selectPaymentSettings = (stackId: string) => {
  const paymentSetting = paymentSettingsPreviews.value.find((s) => s.id === stackId);

  if (!paymentSetting) {
    return;
  }

  if (selectedCurrency.value === 'CAD' && paymentSetting.ppmEnabled) return;

  paymentSettingsId.value = stackId;
};

const selectedProductsCount = computed(() => products.value.length);
const selectedTotalInCents = computed(() => selectedProducts.value.reduce((acc, p) => acc + p.priceInCents, 0));

const grandTotal = inject('grandTotalInCents', ref(0));

watch(selectedTotalInCents, (newVal) => {
  grandTotal.value = newVal;
});

watch(products, (newVal) => {
  if (newVal.length === 0) {
    selectedCurrency.value = null;
  } else if (newVal.length === 1) {
    const product = getProduct(newVal[0]);
    selectedCurrency.value = product?.currencyCode || null;
  }
});

const lnplDisabled = computed(() => {
  if (!selectedPaymentSettings.value) return true;

  const maxPriceLimit = projectConfigs.maxPrice;
  if (selectedPaymentSettings.value.lnplEnabled && maxPriceLimit && selectedTotalInCents.value > maxPriceLimit) {
    return true;
  }

  return false;
});

watch(selectedCurrency, (newVal) => {
  if (newVal === 'CAD' && selectedPaymentSettings.value?.ppmEnabled) {
    paymentSettingsId.value = '';
  }
});

const warnings = computed(() => {
  const list = [];

  // stack
  if (selectedPaymentSettings.value) {
    const maxPriceLimit = projectConfigs.maxPrice;

    if (selectedPaymentSettings.value.lnplEnabled && maxPriceLimit && selectedTotalInCents.value > maxPriceLimit) {
      list.push(`Learn now, pay later will be disabled for this checkout. Your max price limit is ${currencyInCents(maxPriceLimit)}`);
    }

    if (!selectedPaymentSettings.value.ppmEnabled
      && !selectedPaymentSettings.value.paymentPlans.length
      && !selectedPaymentSettings.value.lnplEnabled) {
      list.push('Selected payment settings has no payment options enabled.');
    }

    if (
      !selectedPaymentSettings.value.paymentPlans.length
      && selectedPaymentSettings.value.lnplEnabled
      && maxPriceLimit && selectedTotalInCents.value > maxPriceLimit) {
      list.push('No payment options will be available for this checkout.');
    }

    if (selectedCurrency.value === 'CAD' && selectedPaymentSettings.value.ppmEnabled) {
      list.push('Elective billing is not available for CAD currency.');
    }
  }

  return list;
});

const errors = computed(() => {
  const list = [];

  if (downPaymentTypeError.value) {
    list.push(downPaymentTypeError.value);
  }

  if (downPaymentValueError.value) {
    list.push(downPaymentValueError.value);
  }

  if (paymentSettingsIdError.value) {
    list.push(paymentSettingsIdError.value);
  }

  if (productsError.value) {
    list.push(productsError.value);
  }

  return list;
});

const stackHeaders = [
  {
    title: '# of Payments',
    key: 'numberOfPayments',
  },
  {
    title: 'Down Payment',
    key: 'firstPayment',
  },
  {
    title: 'Monthly Payments',
    key: 'remainingPayments',
  },
];

interface Payment {
    numberOfPayments: number;
    firstPayment: string;
    remainingPayments: string;
    firstPaymentPercentage: number;
}

const paymentSettingsData = computed(() => {
  if (
    !selectedPaymentSettings.value
      || (!selectedPaymentSettings.value.paymentPlans.length && !selectedPaymentSettings.value.paymentPlans.length)
  ) return [];

  const data: Payment[] = [];

  const getPaymentSplit = (installments: number): [number, number] => {
    if (installments === 1) return [selectedTotalInCents.value, 0];

    // calculate the first payment and then the subsequent ones
    if (!downPaymentValue.value) {
      const split = Dinero({ amount: selectedTotalInCents.value })
        .allocate(Array.from(Array(installments)).fill(50));

      return [split[0].getAmount(), split[1].getAmount()];
    }

    let firstPayment = downPaymentValue.value;

    if (downPaymentType.value === 'percentage' && downPaymentValue.value) {
      firstPayment = Dinero({ amount: selectedTotalInCents.value })
        .percentage(downPaymentValue.value)
        .getAmount();
    }

    const remainder = Dinero({ amount: selectedTotalInCents.value })
      .subtract(Dinero({ amount: firstPayment }))
      .allocate(Array.from(Array(installments - 1)).fill(50));

    return [firstPayment, remainder[0].getAmount()];
  };

  const firstPaymentPercentage = (value: number) => Math.round((value / selectedTotalInCents.value) * 10000) / 100;

  selectedPaymentSettings.value.paymentPlans.forEach((plan) => {
    const [first, remainder] = getPaymentSplit(plan.installments);
    data.push({
      numberOfPayments: plan.installments,
      firstPayment: currencyInCents(first),
      firstPaymentPercentage: firstPaymentPercentage(first),
      remainingPayments: currencyInCents(remainder),
    });
  });

  return data.sort((a, b) => a.numberOfPayments - b.numberOfPayments);
});

</script>
