<template>
  <TbNotification
    :title="toastTitle"
    :message="toastText"
    :show="showProjectSwitchToast"
    card-class="bg-success text-white"
    @close="showProjectSwitchToast = false"
  />
  <div
    :key="projectId"
    class="relative flex w-full dashboard"
  >
    <Transition name="slide-in-left">
      <div
        v-if="screenWidth >= 768 || dashboardNavVisible"
        class="dashboard-nav"
      >
        <div class="w-full p-4 bg-[#424A68]">
          <div class="mt-1 ml-1 w-full">
            <TbLetterLogo
              inverted
              class="h-12 w-12"
            />
          </div>

          <div class="flex flex-col justify-between h-full">
            <div class="flex flex-col my-4">
              <div v-if="hasMultipleProjects && !notInSettingsPage">
                <p class="text-white text-sm pb-2">
                  Company
                </p>
                <TbSelect
                  v-model="projectId"
                  :default-options="currentProjectOption"
                  :options="availableProjects"
                  class="custom-select"
                />
              </div>
              <div
                v-for="navRoute in navRoutes"
                :key="navRoute.title"
              >
                <RoleControlledAction
                  v-slot="{restricted}"
                  :user-role="roleName"
                  :config-object="roleConfig[navRoute.pageName]"
                >
                  <router-link
                    v-if="navRoute.hasAccess && navRoute.pageName"
                    :to="restricted ? '' : { name: navRoute.pageName }"
                    class="flex gap-4 nav-link button button--text focus:ring-0"
                  >
                    <component
                      :is="navRoute.icon"
                      v-if="navRoute.icon"
                      class="w-5 h-5"
                    />

                    {{ navRoute.title }}
                  </router-link>
                </RoleControlledAction>
                <template
                  v-for="subRoute in navRoute.subRoutes"
                  :key="subRoute.title"
                >
                  <template
                    v-if="navRoute.subRoutes
                      && shouldShowSubRoutes(navRoute)
                    "
                  >
                    <div
                      v-if="subRoute.hasAccess"
                      class="flex gap-2 ml-4"
                    >
                      <RoleControlledAction
                        v-slot="{restricted}"
                        :user-role="roleName"
                        :config-object="roleConfig[subRoute.pageName]"
                      >
                        <router-link
                          :to="restricted ? '' : { name: subRoute.pageName }"
                          class="flex gap-2 ml-5 text-sm nav-link button button--text focus:ring-0"
                        >
                          <span class="mr-1">&#x2022;</span>
                          {{ subRoute.title }}
                        </router-link>
                      </RoleControlledAction>
                    </div>
                  </template>
                </template>
              </div>
            </div>

            <div class="py-12">
              <RoleControlledAction
                v-slot="{restricted}"
                :user-role="roleName"
                :config-object="roleConfig[Pages.dashboardProfile]"
              >
                <router-link
                  :to="restricted ? '': { name: Pages.dashboardProfile }"
                  class="block text-center text-[#C8E7ED] button bg-white button--block settings-link"
                >
                  <span class="flex items-center justify-center gap-2 text-primary">
                    <TbSettingsIcon class="w-5 h-5" />
                    Settings
                  </span>
                </router-link>
              </RoleControlledAction>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <div class="flex-1 overflow-y-auto bg-[#FDFDFD] dashboard-content">
      <!-- <V2Banner /> -->
      <V2Banner />
      <div class="absolute z-10 right-4 top-4 md:hidden">
        <button
          v-if="screenWidth < 768"
          class="flex items-center justify-center button button--icon button--primary "
          @click="dashboardNavVisible = !dashboardNavVisible"
        >
          <TbMenuIcon class="w-5 h-5" />
        </button>
      </div>

      <div class="w-full min-h-full p-4 md:p-8">
        <Transition name="fade">
          <div
            v-if="screenWidth < 768 && dashboardNavVisible"
            class="fixed top-0 left-0 z-20 w-full h-full transition-colors opacity-30 overlay"
            @click="dashboardNavVisible = false"
          />
        </Transition>

        <TbOverlay
          :show="screenWidth < 768 && dashboardNavVisible"
          @clicked="dashboardNavVisible = false"
        />

        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref, watch, computed, inject, Ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useWindowSize } from 'vue-window-size';
import {
  TbHomeIcon,
  TbStudentIcon,
  TbScaleIcon,
  TbSettingsIcon,
  TbMenuIcon,
  TbOverlay,
  TbCartIcon,
  TbEnvelopeIcon,
  TbBankNotesIcon,
  TbDocumentCheckIcon,
  TbLetterLogo,
  TbSelect,
  TbNotification,
} from '@/components/tasty_bistro';
import { AppState } from '@/pages/app/api/get_app_state';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { Pages } from '@/router';
import { roleConfig } from './role_configurations';
import V2Banner from './v2_banner/index.vue';

const { width: screenWidth } = useWindowSize();
const route = useRoute();
const router = useRouter();
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = ref<string>(route.params.projectId as string);
const dashboardNavVisible = ref(true);
const currentRoutePageName = computed(() => route.name);

const features = computed(() => appState.value.projects[projectId.value].features);

watch(() => route.path, () => { dashboardNavVisible.value = false; });

const roleName = computed(() => appState.value.projects[projectId.value].currentUserRoleName);

interface Page {
  title: string;
  pageName?: any,
  hasAccess: boolean;
  icon?: any;
  subRoutes?: Page[]
}

const isSubpageSelected = (navRoute: Page) => {
  if (navRoute.subRoutes) {
    return navRoute.subRoutes.some((sub) => sub.pageName === currentRoutePageName.value);
  }
  return false;
};

const shouldShowSubRoutes = (navRoute: Page) => {
  // Check if current route is the main route
  if (navRoute.pageName === currentRoutePageName.value) {
    return true;
  }

  // Check if one of the sub-routes is the current route
  return isSubpageSelected(navRoute);
};

const navRoutes: Page[] = [
  {
    title: 'Home', pageName: Pages.dashboardHome, hasAccess: true, icon: TbHomeIcon,
  },
  {
    title: 'Checkouts',
    pageName: Pages.checkouts,
    hasAccess: true,
    icon: TbCartIcon,
    subRoutes: [
      {
        title: 'Products',
        pageName: Pages.products,
        hasAccess: true,
      },
      {
        title: 'Payment Settings',
        pageName: Pages.paymentSettings,
        hasAccess: true,
      }, {
        title: 'Invoicing',
        pageName: Pages.invoices,
        hasAccess: Boolean(features.value?.invoicingEnabled),
      },
    ],
  },
  {
    title: 'Applications', pageName: Pages.dashboardApplications, hasAccess: true, icon: TbEnvelopeIcon,
  },
  {
    title: 'Orders', pageName: Pages.dashboardOrders, hasAccess: true, icon: TbDocumentCheckIcon,
  },
  {
    title: 'Payments',
    pageName: Pages.dashboardInHousePayments,
    hasAccess: !!features.value?.ppmEnabled,
    icon: TbBankNotesIcon,
    subRoutes: [
      {
        title: 'Billing Report',
        pageName: Pages.dashboardInHousePaymentsAnalytics,
        hasAccess: !!features.value?.ppmEnabled,
      }],
  },
  {
    title: 'Customers', pageName: Pages.dashboardStudents, hasAccess: true, icon: TbStudentIcon,
  },
  {
    title: 'Payouts', pageName: Pages.dashboardSettlements, hasAccess: true, icon: TbScaleIcon,
  },
];

function hasRouteAccess(routeName: string) {
  const route = navRoutes.find((r) => r.pageName === routeName);
  const routeConfig = roleConfig[routeName];
  if (!routeConfig) {
    return false;
  }
  const roleBlocked = routeConfig[roleName.value]?.restricted || routeConfig[roleName.value]?.hidden;
  return route?.hasAccess && !roleBlocked;
}

const currentProjectName = computed(() => appState.value.projects[projectId.value].name);
const currentProjectOption = computed(() => ({
  name: currentProjectName.value,
  value: projectId.value,
}));

const showProjectSwitchToast = ref(false);
const toastTitle = computed(() => `You are now viewing ${currentProjectName.value}`);
const toastText = computed(() => `You're now viewing ${currentProjectName.value}'s dashboards.`);

const availableProjects = computed(() => {
  const { projects } = appState.value;
  return Object.keys(projects)
    .filter((id) => id !== currentProjectOption.value.value)
    .map((id) => ({ name: projects[id].name, value: id }));
});
const hasMultipleProjects = computed(() => Object.keys(appState.value.projects).length > 1);
const notInSettingsPage = computed(() => {
  const settingsRoutes = [
    Pages.dashboardProfile,
    Pages.userManagement,
    Pages.dashboardIntegrations,
    Pages.dashboardSettingsLogo,
    Pages.dashboardNotificationsSubscribers,
  ];
  return settingsRoutes.includes(route.name as any);
});

watch(() => projectId.value, (newVal) => {
  // looks silly but it's to ensure that a toast is shown even when someone switches to another project while a toast was already showing.
  showProjectSwitchToast.value = false;
  showProjectSwitchToast.value = true;

  if (!hasRouteAccess(route.name as string)) {
    router.push({ name: Pages.checkouts, params: { projectId: newVal } });
  } else {
    router.push({ name: route.name as any, params: { projectId: newVal } });
  }
  setTimeout(() => {
    showProjectSwitchToast.value = false;
  }, 2_000);
});

</script>

<style lang="scss" scoped>
.dashboard {
  min-height: 100%;
  height: 100%;

  .dashboard-nav {
    @apply
      absolute
      z-30
      flex
      h-full
      overflow-hidden
      transition-all
      duration-200
      shadow-md
      md:relative
      md:translate-x-0
      w-64
      bg-primary-100;

    @media (max-width: 767px ) {
      @apply z-40
    }
  }

  .dashboard-content {
    @apply
      z-30
  }

  .nav-link {
    @apply text-[#F1F9FB];

    &:hover {
      @apply text-[#C8E7ED];
    }
    &.router-link-active {
      @apply font-semibold text-[#F1F9FB];
    }
  }

  .slide-in-left-enter-active,
  .slide-in-left-leave-active {
    @apply transition-transform duration-300;
  }

  .slide-in-left-enter-from,
  .slide-in-left-leave-to {
    @apply -translate-x-60;
  }
}

.custom-select{
  @apply w-full pb-4;
}

.custom-select :deep select {
  @apply pr-8;
}
</style>
