<template>
  <div
    v-if="$slots.default"
    ref="dropdown"
    class=""
  >
    <slot
      name="toggle"
      :toggleDropdown="toggleDropdown"
    />

    <div
      v-show="show"
      ref="floating"
      class="p-1 bg-white rounded-lg shadow-md min-w-[220px] max-w-3xl w-full border-gray-100 border-[1px] content z-10"
      :style="{
        position: 'fixed',
        top: `${y ?? 0}px`,
        left: `${x ?? 0}px`,
        width: 'max-content',
      }"
    >
      <slot :toggleDropdown="toggleDropdown" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import {
  useFloating, shift, flip, autoUpdate, offset,
} from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';

const show = ref<boolean>(false);
const dropdown = ref(null);
const floating = ref(null);
const { x, y } = useFloating(dropdown, floating, {
  placement: 'bottom-start',
  middleware: [offset({ mainAxis: 10, crossAxis: 10 }), flip(), shift()],
  whileElementsMounted: autoUpdate,
});

const toggleDropdown = () => {
  show.value = !show.value;
};

onClickOutside(dropdown, () => {
  if (show.value === false) return;
  show.value = false;
});
</script>

<style lang="scss" scoped>
  :slotted(.dropdown-item) {
    &:hover {
      @apply bg-gray-100 cursor-pointer;
    }
  }
</style>
